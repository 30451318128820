import { css } from 'astroturf';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

const styles = css`
  .feature {
    padding: 5rem 2rem;
    max-width: 1200px;
    margin: 0 auto;

    @media (min-width: 800px) {
      padding: 8rem 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      & .image {
        overflow: visible;

        & img {
          transition: 1s transform ease;
          transform: perspective(800px) rotateY(0.1deg);
        }
        &:hover img {
          transform: perspective(800px) rotateY(-4deg);
        }
      }
      &:nth-child(even) {
        flex-direction: row-reverse;

        & .text {
          @media (min-width: 800px) {
            padding: 0 0 0 4rem;
          }
        }
        & .image {
          &:hover img {
            transform: perspective(800px) rotateY(4deg);
          }
          &:not(.noshadow)::before {
            background: linear-gradient(to right bottom, rgb(114, 198, 239), rgb(0, 78, 143));
          }
        }
      }
    }

    & > div {
      position: relative;
      flex: 1 1 0%;
      &:last-child {
        flex: 2 1 0%;
      }
    }

    &.light .text {
      & h3 {
        color: #0a1530;
      }
      & p {
        color: #000;
      }
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    padding-bottom: 2rem;
    @media (min-width: 800px) {
      padding: 0 4rem 0 0;
    }
    & h3 {
      width: 100%;
      text-align: left;
      max-width: 30rem;
      font-weight: 900;
      font-size: clamp(18px, 16px + 3vw, 40px);
      line-height: 1.1;
      color: #fff;
      margin: 0 auto;
      text-wrap: balance;
    }

    &.smalltitle h3 {
      font-size: clamp(18px, 16px + 3vw, 32px);
    }

    & ul,
    & p {
      margin: 2rem auto 0;
      max-width: 30rem;
      color: rgba(255, 255, 255, 0.9);
      width: 100%;
      font-size: calc(16px + 0.25vw);
      @media (min-width: 770px) {
        font-size: 1.17rem;
      }
    }
    & .brow {
      width: 100%;
      text-align: left;
      margin: 0 auto;
      max-width: min(100%, 30rem);
      width: 30rem;
      color: #007ca5;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.8em;
      background: radial-gradient(#67b26f, ease-in-out, #007ca5);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    & ul {
      width: 100%;
      padding-left: 0;
      list-style: none;
      line-height: 1.5;

      & svg {
        display: inline-block;
        color: #107db5;
        width: 16px;
        height: 16px;
        stroke-width: 2px;
        vertical-align: middle;
        opacity: 0.6;
        margin-left: calc(-1 * 16px);

        @media (min-width: 800px) {
          margin-left: calc(-1 * 16px);
          margin-right: 0.5rem;
        }
      }
    }
  }

  .image {
    position:relative;
    max-width: 50%;
    flex-basis: 50%;
    flex-grow: 0;
    margin-top: 0rem;
    display: none;
    overflow: visible;

    @media (min-width: 800px) {
      display: block;
      margin: 0 auto;
      max-height: 200px;
    }

    &:not(.noshadow)::before {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 101%;
      height: 101%;
      border-radius: 4px;
      background: linear-gradient(
        to right bottom,
        rgb(31, 0, 92),
        rgb(91, 0, 96),
        rgb(135, 1, 96),
        rgb(172, 37, 94),
        rgb(202, 72, 92),
        rgb(225, 107, 92),
        rgb(243, 144, 96),
        rgb(255, 181, 107)
      );
      transform: rotate(4deg) translateZ(-40px);
      transform-origin: 20% 70%;
      box-shadow: rgba(255, 255, 255, 0.15) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.3) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.5) 0px 30px 60px -30px, rgba(103, 178, 111, 0.3) -10px 10px 60px -10px;
    }
  }

  .full {
    position:relative;
    display: block;
    max-width: 100%;
    max-height: 100%;

    &:not(.noshadow) img {
      display: block;
      max-width: 100%;
      border-radius: 4px;
      box-shadow: rgba(255, 255, 255, 0.15) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.3) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.5) 0px 30px 60px -30px, rgba(103, 178, 111, 0.3) -10px 10px 60px -10px;
    }
  }

  .noshadow {
    display: block;
    box-shadow: none;
    background: transparent;
    max-height: 100%;
    max-width: 100%;

    @media (min-width: 480px) {
      max-width: 400px;
    }
  }
`;

function Feature(props) {
  return (
    <div className={[styles.feature, props.type ? styles[props.type] : ''].join(' ')}>
      <div className={[styles.text, props.smalltitle ? styles.smalltitle : ''].join('  ')}>
        {props.brow && <span className={styles.brow}>{props.brow}</span>}
        {props.children}
      </div>
      {props.gatsbyImg && (
        <GatsbyImage
          image={props.gatsbyImg.childImageSharp.gatsbyImageData}
          className={[styles.image, props.full ? styles.full : '', props.noshadow ? styles.noshadow : ''].join(' ')}
          alt=""
          title=""
        />
      )}
      {props.screenshot && (
        <div className={[styles.image, props.full ? styles.full : '', props.noshadow ? styles.noshadow : ''].join(' ')}>
          <img
            style={{ borderRadius: props.borderRadius }}
            src={props.screenshot.publicURL || props.screenshot.src}
            alt=""
            title=""
          />
        </div>
      )}
    </div>
  );
}

export default Feature;
