import React, { Component } from 'react';

import List from '../components/Accolades/list';
import CTA from '../components/CTA';
import PageHeader from '../components/PageHeader';
import SEO from '../components/SEO';
import Layout from '../layout';

class Page extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Accolades given to Polypane"
          pathname={this.props.location.pathname}
          description="Accolades, recognition and awards given to Polypane."
        />
        <PageHeader>
          <h1>Accolades</h1>
          <h2>Awards and recognition given to Polypane</h2>
          <p>
            We're super proud whenever Polypane gets recognized! Check out the different ways Polypane has been
            recognized over the years.
          </p>
        </PageHeader>
        <List />
        <CTA />
      </Layout>
    );
  }
}

export default Page;
